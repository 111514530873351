<template>
    <div class="d-flex flex-row mt-3">
        <MDBInput class="wx-input bg-white form-icon-trailing" :formOutline="false" size="lg" inputGroup="lg" wrapperClass="mb-3"
            placeholder="输入中文" v-model="input">
 
            <template #prepend>
                <MDBBtn  class="wx-micro-btn border shadow-0" @mouseup="recordEnd" @mousedown="recordStart" @touchstart="recordStart"
                    @touchend="recordEnd">
                    <MDBIcon icon="fas fa-microphone-alt wx-micro" size="lg"></MDBIcon>
                </MDBBtn>
            </template>

            <div class="border wx-hit-x"  >
                <MDBIcon v-show="xShow" icon="fas fa-times-circle" @click="clickX"></MDBIcon>
            </div>
            
            
            <MDBBtn class="wx-micro-btn border shadow-0"  @click="more" v-show="moreShow" size="lg">
                <MDBIcon class="wx-search" icon="search" size="lg" />
            </MDBBtn>

        </MDBInput>

    </div>

    <MDBPopover v-model="recordModal">
        <template #body>
            开始录音,松开手结束录音
        </template>
    </MDBPopover>
</template>
  
<script>

import { MDBInput, MDBBtn, MDBIcon } from 'mdb-vue-ui-kit';
import {
    MDBPopover
} from 'mdb-vue-ui-kit';
import axios from 'axios'
import wxApi from '@/utils/wxapi';
import qs from 'qs'

export default {

    name: 'SearchBar',
    components: { MDBInput, MDBBtn, MDBIcon, MDBPopover },
    data() {
        return {
            input: "",
            moreShow: false,
            xShow: false,
            recordModal: false
        }
    },
    created() {
        this.input = this.$store.state.user.input;
    },
    methods: {
        more() {
            axios.get("/api/more?" + qs.stringify({
                "token": this.$store.state.token
                , "key": this.input
            })).then(resp => {
                let item = resp.data

                this.$emit("more", item)

                this.moreShow = false;
            })
        },
        recordStart() {
            this.recordModal = true

            wxApi.sdk.startRecord();

        },
        recordEnd() {
            this.recordModal = false

            wxApi.sdk.stopRecord({
                success: (res) => {
                    let localId = res.localId;

                    wxApi.sdk.translateVoice({
                        localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: (res) => {
                            this.input = res.translateResult; // 语音识别的结果
                        }
                    });
                }
            });
        },
        clickX() {
            this.input = ""
        }
    },
    watch: {
        input(newVal, oldVal) {

            if (newVal == '') {
                this.items = []
                this.xShow = false
                return;
            }else {
                this.xShow = true
            }

            if (newVal == oldVal || isInputDirtyVal(newVal, oldVal)) {
                return;
            }


            axios.get("/api/searchEng?" + qs.stringify({
                "token": this.$store.state.token
                , "key": newVal
            })).then((resp) => {

                let items = resp.data

                this.moreShow = true
                for (let i in items) {
                    if (trimNoChinese(items[i].zh).search(trimNoChinese(newVal.trim())) != -1) { //有匹配上
                        this.moreShow = false
                        break
                    }
                }

                this.$store.commit("setInput", newVal);
                this.$emit("input", items)

                
            })


        }
    },
    emits: ['input', 'more']
}



function isInputDirtyVal(newVal, oldVal) {
    let hash1 = {};
    for (let i in newVal) {
        if (hash1[newVal[i]] === undefined) {
            hash1[newVal[i]] = 1;
        } else {
            hash1[newVal[i]] += 1;
        }
    }

    let hash2 = {};
    for (let i in oldVal) {
        if (hash2[oldVal[i]] === undefined) {
            hash2[oldVal[i]] = 1;
        } else {
            hash2[oldVal[i]] += 1;
        }
    }

    for (let k2 in hash2) {
        if (hash1[k2] === undefined) {
            continue;
        }
        hash1[k2] = hash1[k2] - hash2[k2];

        if (hash1[k2] < 1) {
            delete hash1[k2];
        }
    }

    let tail = [];

    for (let k1 in hash1) {
        tail.push(k1);
    }

    if (tail.length == 0) {
        if (isChinese(newVal)) {
            return false;
        }
        return false;
    }

    let tailStr = tail.join("");

    if (isChinese(tailStr)) {
        return false;
    }

    return true;
}

function trimNoChinese(s) {
    let ns = [];
    for (let i in s) {
        if (isChinese(s[i])) {
            ns.push(s[i]);
        }
    }
    return ns.join("");
}

function isChinese(s) {
    let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    if (reg.test(s)) {
        return true;
    } else {
        return false;
    }
}
</script>
  
<style>
    .wx-micro {
  color: #6EBEB3;
 
}

.wx-search {
  color: #6EBEB3
}

.wx-hit-cate {
  background-color: #E4F5EB;
  color: #6EBEB3
}

.wx-input {

    border-top-color: #6EBEB3;
    border-right: none;
    border-bottom-color: #6EBEB3;
    border-left-color: #6EBEB3;
}

.wx-input:focus {
    border-color: #6EBEB3!important;  
    box-shadow: none!important;
}

.wx-micro-btn {
    background-color: #E4F5EB; 
    border-color: #6EBEB3!important;
}

.wx-hit-x { 
    border-top-color: #6EBEB3!important;
    border-right-color: #6EBEB3!important;
    border-bottom-color: #6EBEB3!important;
    border-left: none!important;
    color: #E4F5EB;
    display: flex; 
    justify-content: center;  
    align-items: center;  
    padding-right: 5px;
}
</style>