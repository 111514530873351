import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/HomeView.vue";
import MyFavor from "./views/MyFavor.vue";
import SceneView from "./views/SceneView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/index.html",
    name: "home",
    component: HomeView,
  },
  {
    path: "/favor",
    name: "MyFavor",
   
    component: MyFavor,
  },
  {
    path: "/scene",
    name: "Scene",
    component: SceneView,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
