<template>
  <div>

    <div class="my-4"></div>

    <HitItem v-for="item in items" :key="item.talkId" :typ="item.typ" :zh="item.zh" :highlight="item.highlight"
      :en="item.en" :talkId="item.talkId" :isFav="item.isFav"></HitItem>

    <div class="py-4"></div>
  </div>
</template>

<script>

import HitItem from '../components/HitItem'
// import SearchBar from '../components/SearchBar'
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'MyFavor',
  components: { HitItem },
  data() {
    return {
      items: []
    }
  },
  created() {
    axios.get("/api/listFavor?" + qs.stringify({
                "token": this.$store.state.token})).then(resp => {
                  this.items = resp.data
            });
  } 
}

</script>


<style></style>