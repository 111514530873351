<template>
  <div :class="bgClass">
    <div class="d-flex justify-content-between align-items-stretch shadow-3-strong rounded-5 px-3 py-1 mt-3">
      <div>
            <span v-if="typ != undefined">
              <router-link :to="scenelink"><span class="fw-bold rounded-2 wx-hit-cate fs-5 me-3">{{ typ }}</span></router-link> 
            </span>
        
        <span class="fw-bold wx-zh-text fs-5" v-html="zhHtml"></span>

        <p class="lh-base text-break mt-2">
         
          <span class="wx-en-text fw-bold fs-5"> {{ en }}</span>
          
          <i class="ms-1" :class="speakIconClass" @click="playback">
            <audio v-if="openAudio">
              <source :src="audioUrl" type="audio/mpeg">
            </audio>
          </i>
          

        </p>
      </div>


      <div class="d-flex flex-column justify-content-between">
        <div>
          <i :class="favIconClass" @click="addFav"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import qs from 'qs'

export default {

  name: 'HitItem',
  data() {
    return {
      favIconClass: 'far fa-heart wx-hit-unfav',
      speakIconClass: 'fas fa-volume-off wx-hit-vol',
      bgClass: "wx-hit-bg",
      openAudio: false,
      zhHtml : ""
    }
  },
  props: {
    typ: String,
    zh: String,
    en: String,
    fav: String,
    highlight: String,
    talkId: String,
    isFav: String,
    said: String

  },
  created() {
    this.isFav == "X" ? this.favIconClass = "fas fa-heart wx-hit-fav" : this.favIconClass = "far fa-heart wx-hit-unfav"

    if (this.highlight != undefined) {
      this.zhHtml = this.highlight
    }else {
      this.zhHtml = this.zh
    }

    if (this.highlight == undefined) { //没有高亮搜索命中的情况下才生效
      if (this.said == "宝宝") {
        this.bgClass = "wx-hit-bg-baby"
      } else {
        this.bgClass = "wx-hit-bg-parents"
      }
    }


  },
  computed: {
    audioUrl() {
      return "audio/" + this.talkId + ".mp3";
    },
    scenelink() {
      return { path: 'scene', query: { typ: this.typ } }
    }
  },
  methods: {
    addFav() {

      //console.log("收藏动作 talkId:" + this.talkId)
      if (this.favIconClass == "far fa-heart wx-hit-unfav") { //未收藏

        axios.get("/api/addFavor?" + qs.stringify({
          "token": this.$store.state.token
          , "talkId": this.talkId, "en": this.en, "zh": this.zh, "typ": this.typ
        }))
        this.favIconClass = "fas fa-heart wx-hit-fav"

      } else {
        axios.get("/api/cancelFavor?" + qs.stringify({
          "token": this.$store.state.token
          , "talkId": this.talkId
        }))
        this.favIconClass = "far fa-heart wx-hit-unfav"
      }
    },
    playback(event) {

      if (this.openAudio == false) {
        this.openAudio = true

        this.$nextTick(() => {
          let audio = event.target.getElementsByTagName("audio")[0]

          if (this.speakIconClass.indexOf("fa-volume-off") == -1) {
            this.speakIconClass = 'fas fa-volume-off wx-hit-vol'
            audio.pause()
          } else {
            this.speakIconClass = 'fas fa-volume-up wx-hit-vol'
            audio.currentTime = 0;
            audio.play();
            audio.onended = () => {
              this.speakIconClass = 'fas fa-volume-off wx-hit-vol'
            }
          }
        })

        return
      }


      let audio = event.target.getElementsByTagName("audio")[0]

      if (this.speakIconClass.indexOf("fa-volume-off") == -1) {
        this.speakIconClass = 'fas fa-volume-off wx-hit-vol'
        audio.pause()
      } else {
        this.speakIconClass = 'fas fa-volume-up wx-hit-vol'
        audio.currentTime = 0;
        audio.play();
        audio.onended = () => {
          this.speakIconClass = 'fas fa-volume-off wx-hit-vol'
        }
      }

    }
  }
}
</script>

<style>
.wx-zh-highlight {
  background-color: #FEA871;
  border-radius: 2px;
  /* font-style: oblique; 设置字体样式为斜体*/
}

.wx-zh-text {
  color: #1A1A1A;
  line-height: 1.2;
}

.wx-en-text {
  color: #1A1A1A;
  line-height: 1.2;
}

.wx-hit-bg {
  background-color: #fff;
}

.wx-hit-bg-parents {
  background-color: #fff;
  width: 95%
}

.wx-hit-bg-baby {
  background-color: #E4F5EB;
  margin-left: auto;
  /* text-align: right; */
  width: 95%
}

.wx-hit-cate {
  background-color: #E4F5EB;
  color: #6EBEB3
}

.wx-hit-unfav {
  color: #D4D4D4
}

.wx-hit-fav {
  color: #FEA871
}

.wx-hit-vol {
  color: #6EBEB3
}</style>