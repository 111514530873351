import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist' 


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: { 
    token: "",
    user: {babyname: "宝宝", input: "你好"}
  }, 
  mutations: {
    setToken(state, payload) {
       
      state.token = payload
    },
    setBabyName(state, payload) {
      state.user.babyname = payload;
    },
    setInput(state, payload) {
      state.user.input = payload;
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
});
