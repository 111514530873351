<template>
  <div>
    <div class="titlescene">{{ sceneName }}</div>

    <HitItem v-for="item in items" :key="item.talkId" :typ="item.typ" :zh="item.zh" :said="item.said"
      :en="item.en" :talkId="item.talkId" :isFav="item.isFav"></HitItem>

    <div class="py-4"></div>

  </div>
</template>
  
<script>

import HitItem from '../components/HitItem'
import axios from 'axios'
import qs from 'qs'


export default {
  name: 'SceneView',
  components: { HitItem },
  data() {
    return {
      sceneName: "",
      items: []
    };
  },
  created() {

    this.sceneName = this.$route.query.typ
 

    axios.get("/api/listEngBySence?" + qs.stringify({
                "token": this.$store.state.token, "typ": this.sceneName})).then(resp => {
                  this.items = resp.data
            });

  }

}

</script>
  
<style scoped>
.titlescene{
  color: #6EBEB3;
  font-size: larger;
  font-weight: bolder;
  text-align: center;
  margin: auto;
  background-color: #E4F5EB;
  display: flex; /* 使用flex布局 */  
    justify-content: center; /* 主轴对齐方式，center表示居中 */  
    align-items: center; /* 侧轴对齐方式，center表示居中 */ 
    height: 50px
}
</style>