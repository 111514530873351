<template>
  <div>

    <SearchBar @more="more" @input="input"></SearchBar>

    <HitItem v-for="item in items" :key="item.talkId" :typ="item.typ" :zh="item.zh" :highlight="item.highlight"
      :en="item.en" :talkId="item.talkId" :isFav="item.isFav"></HitItem>

    <div class="py-4"></div>
  </div>
</template>

<script>

import HitItem from '../components/HitItem'
import SearchBar from '../components/SearchBar'


export default {
  name: 'HomeView',
  components: { HitItem, SearchBar },
  data() {
    return {
      items: []
    }
  },
  methods: {
    more(item) {

      this.items.unshift(item);
      this.items.pop();

    },
    input(items) {
      this.items = items
    }
  }
}

</script>


<style></style>