import wx from "weixin-js-sdk";
import axios from "axios";
import qs from 'qs'

const wxApi = {
  sdk: wx,
  message: "",
  init(vue) {

     
      let params = qs.parse(window.location.search , { ignoreQueryPrefix: true })
      //console.log(queryString)
      if ( params.code != null ) {
         axios.post("/api/loginByWxCode?" + qs.stringify({'code' : params.code}))
          .then(resp => {
            vue.$store.commit("setToken", resp.data.token)

            delete params.code
            delete params.state

            if ( Object.keys(params).length > 0 ) {
              history.pushState({page: 1}, document.title, "?" + qs.stringify(params));
            }else {
              history.pushState({page: 1}, document.title, window.location.href.split('?')[0] );
            }
            
          })
      }
 
    
    axios.get("/api/wechat/jsapi?" + qs.stringify({"url" : window.location.href})).then((resp) => {
      wx.config({
        debug: false,
        appId: resp.data.appId,
        timestamp: resp.data.timestamp,
        nonceStr: resp.data.nonceStr,
        signature: resp.data.signature,
        jsApiList: ["checkJsApi", "updateAppMessageShareData"
        ,"chooseImage", "startRecord", "stopRecord",  "translateVoice"],
      });

      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: "Talkbaby和宝宝一起说英语", // 分享标题
          desc: "不用学习就能说英语", // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: window.location.href + "/favicon.ico", // 分享图标
          success: function () {
             
          },fail(res){
            console.log(res);
            // alert("分享失败！"+JSON.stringify(res));
          }
        });
      });

      wx.error((res) => {
        console.log(res);
      });
    });
  },
};

export default wxApi;
