<template>
  <MDBContainer class="wx-body-bg rounded-6 min-vh-100 text-white">
    <MDBNavbar class="shadow-0" container>

      <MDBNavbarBrand href="#" class="text-black "> 
        <router-link to="/index.html">
          <img src="@/assets/logo.png" height="50" alt="" loading="lazy" />
        </router-link>
          <span class="fs-3 ms-3 wx-fc">和宝宝说英语</span> 
        
      </MDBNavbarBrand>
      <MDBNavbarNav >
        <MDBNavbarItem class="dropdown" >
          <MDBDropdown v-model="dropdown">
            <MDBDropdownToggle tag="a" class="nav-link" @click="dropdown = !dropdown">
              <MDBIcon icon="bars" size="2x" class="wx-fc " />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <router-link to="/index.html">
                <MDBDropdownItem href="#">首页</MDBDropdownItem>
              </router-link>
              <router-link to="/favor">
                <MDBDropdownItem href="#">我的收藏</MDBDropdownItem>
              </router-link>
              <!-- <MDBDropdownItem @click="this.babyNameModal = true" href="#">设置宝宝信息</MDBDropdownItem> -->
              <MDBDropdownItem divider />
              <MDBDropdownItem href="#">使用技巧</MDBDropdownItem>
              <MDBDropdownItem href="#">关于我们</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      </MDBNavbarNav>

    </MDBNavbar>


    <router-view />

  </MDBContainer>
</template>

<script>

import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBDropdownToggle, MDBDropdownItem, MDBDropdown, MDBDropdownMenu, MDBIcon } from 'mdb-vue-ui-kit';
import wxApi from './utils/wxapi'



export default {
  name: 'App',
  components: {
    MDBContainer, MDBNavbar, MDBNavbarBrand, MDBDropdownToggle, MDBDropdownItem, MDBDropdown, MDBDropdownMenu, MDBIcon,  
  },
  data() {
    return {
      dropdown: false,
      input: "",
      url: "",
      babyNameModal: false
    }
  },
  computed: {
    babyNameInput: {
      get() {
        return this.$store.state.user.babyname;
      },
      set(value) {
        this.$store.commit("setBabyName", value);
      }
    }
  },
  methods: {

  },
  mounted() {

    wxApi.init(this)

  }
}
</script>

<style lang="scss">
// @import '~@/../mdb/scss/index.free.scss';

.wx-body-bg {
  //background-color: #1565c0;
  background-color: #ffffff;
}

.wx-fc {
  color: #6EBEB3;
  font-weight: bolder
}

</style>
